import generateOptions from "@/components/contentful/common/richtext/richtext-options";
import { retrieveImageUrlFromMediaWrapper } from "@/lib/contentful/helpers/common";
import { ISideBySide } from "@/lib/contentful/interfaces/components";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { usePage } from "@inertiajs/react";
import { Chip } from "@nextui-org/chip";
import { Image } from "@nextui-org/react";
import { ScrollShadow } from "@nextui-org/scroll-shadow";
import { useIntersectionObserver } from "@uidotdev/usehooks";
import { cva, cx, VariantProps } from "class-variance-authority";
import { motion } from "framer-motion";
import React, { FC, HTMLAttributes, useEffect, useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { useTracking } from "react-tracking";
import { useSection } from "../../lesson-page/section-context";
import { useTrackingContext } from "@/context/tracking-context";

const componentContainer = cva("my-20 mb-4x spacing-component-max-width", {
    variants: {
        backgroundColor: {
            Default: "",
            Primary: "bg-background-100x text-primary-foregroundx",
            Secondary: "bg-secondary-900x text-secondary-foregroundx bg-content1",
            None: "text-primary-foregroundx",
        },

        // Default: " bg-content1",
        // Primary: " bg-content3",
        // Secondary: "text-secondary-foregroundx",
        // None: "",
    },
    defaultVariants: {
        backgroundColor: "Primary",
    },
});

const sidebysideBodyContainer = cva([""], {
    variants: {
        hasImage: {
            true: "lg:w-[50%] h-full",
            false: "w-full h-full",
        },
    },
    defaultVariants: {
        hasImage: false,
    },
});

const sidebysideContentSection = cva(["flex gap-8 w-full"], {
    variants: {
        imageAlignment: {
            "Right": "flex-col lg:flex-row",
            "Left": "flex-col lg:flex-row",
            "Top": "flex-col",
            "Bottom": "flex-col",
        },
    },
    defaultVariants: {
        imageAlignment: "Right",
    },
});

const sidebysideImageSection = cva(["w-full  "], {
    variants: {
        imageAlignment: {
            "Right": "lg:w-[50%] order-last",
            "Left": "lg:w-[50%] order-first",
            "Top": "",
            "Bottom": "order-first",
        },
    },
    defaultVariants: {
        imageAlignment: "Right",
    },
});

interface SideBySideWrapperProps
    extends HTMLAttributes<HTMLDivElement>,
        VariantProps<typeof componentContainer> {
    children: React.ReactNode;
    entry: ISideBySide;
}

const SideBySide: FC<ISideBySide> = (entry) => {
    const {track} = useTrackingContext()
   
   
    const body = entry?.fields?.body || "";
    const title = entry?.fields?.title || "";
    const backgroundColor = entry?.fields?.backgroundColor || "Default";
    const img = entry?.fields?.image
        ? retrieveImageUrlFromMediaWrapper(entry?.fields?.image)
        : "";

        const imageAlignment = entry?.fields?.imageAlignment || "Right";

    return (
        <div>
            <motion.div
                initial={{ opacity: 1, scale: 1 }}
                whileInView={{ opacity: 1 }}
                // viewport={{ root: scrollRef }}
            >
                <DivWrapper entry={entry} backgroundColor={backgroundColor}>
                    <div className={cx(sidebysideContentSection({ imageAlignment }))}>
                        <ScrollShadow
                            className={cx(
                                sidebysideBodyContainer({
                                    hasImage: !!entry?.fields?.image,
                                })
                            )}
                        >
                            <div className="flex flex-col gap-8">
                                <h2 className="">{title}</h2>
                                {body
                                    ? documentToReactComponents(
                                          body,
                                          generateOptions()
                                      )
                                    : ""}
                            </div>
                        </ScrollShadow>

                        {img && (
                            <div className={cx(sidebysideImageSection({ imageAlignment }))}>
                                <Image
                                    isBlurred={img?.enableBlur}
                                    isZoomed={img?.enableZoom}
                                    radius={img?.radius || "none"}
                                    alt={img?.alt || ""}
                                    src={img?.url}
                                    fallbackSrc={img?.url}
                                    classNames={{
                                        img: "w-full h-full object-contain ",
                                        wrapper: "h-full w-full",
                                        zoomedWrapper: "h-full w-full",
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </DivWrapper>
            </motion.div>
        </div>
    );
};

const DivWrapper: FC<SideBySideWrapperProps> = ({
    backgroundColor = "Primary",
    children,
    entry: { fields, sys },
}) => {
    const {
        auth,
        appName,
        lessonBackendData,
        courseId,
        lessonStatements,
        session_id
    }: any = usePage().props;

    const { trackEvent, Track, getTrackingData }: any = useTracking({
        course_id: courseId,
        lesson_id: lessonBackendData?.contentful_id,
        section_id: sys?.id,
    });

    const {track} = useTrackingContext()
    // const trackedData = getTrackingData();
    const { currentSectionId, setCurrentSectionId } = useSection();

    const [hasViewed, setHasViewed] = useState(false);
    const [hasStarted, setHasStarted] = useState(false);
    const [hasCompleted, setHasCompleted] = useState(false);
    const [hasSavedTracking, setHasSavedTracking] = useState(false);

    const handleLessonComplete = () => {
        track({
            event_key: "lesson.element.complete",
            event_value: {
              user_id: session_id,
              course_id: courseId,
              lesson_id: lessonBackendData?.contentful_id,
              section_id: sys?.id,
              time: Date.now()
            }
          })
        if (!hasSavedTracking) {
            trackEvent({
                event: "completed",
                type: "lesson-section",
                object: {
                    lesson_id: lessonBackendData?.contentful_id,
                    section_id: sys?.id,
                    course_id: courseId,
                },
                props: { learnerId: 123 },
            });
            setHasSavedTracking(true);
        }
    };
    const [ref, entry] = useIntersectionObserver({
        threshold: 1,
        root: null,
        rootMargin: "0px",
    });

    useEffect(() => {
        const completedSectionStatement = lessonStatements.find(
            (stmt: any) =>
                stmt?.object?.lesson_id === lessonBackendData?.contentful_id &&
                stmt?.object?.section_id === sys?.id &&
                stmt?.verb === "completed"
        );
        if (completedSectionStatement) {
            if (!hasCompleted) {
                setHasCompleted(true);
            }
        }
    }, [lessonStatements, lessonBackendData?.contentful_id, sys?.id]);

    useEffect(() => {
        if (entry?.isIntersecting) {
            setCurrentSectionId(sys?.id);
            if (!hasStarted) {
                setHasStarted(true);
            }
        } else if (hasStarted && !hasCompleted) {
            setHasCompleted(true);
            handleLessonComplete();
        }
    }, [entry?.isIntersecting, hasStarted, hasCompleted, hasSavedTracking]);

    return (
        <div
            id={sys?.id}
            ref={ref}
            className={cx(componentContainer({ backgroundColor }))}
        >
            <motion.div
                initial={{ opacity: 0, y: 100 }}
                animate={{
                    opacity: hasStarted ? 1 : 0.7,
                    y: entry?.isIntersecting ? 0 : 5,
                    z: entry?.isIntersecting && !hasCompleted ? 1 : 10,
                }}
                transition={{ duration: 0.5 }}
                className="w-full"
            >
                {/* <pre className="">
                    {JSON.stringify(lessonStatements, null, 2)}
                </pre> */}
                <div className="py-2">{children}</div>
                {hasCompleted && (
                    <Chip
                        variant="faded"
                        startContent={<FaCheckCircle />}
                        color="success"
                    >
                        Completed
                    </Chip>
                )}
            </motion.div>
        </div>
    );
};

export default SideBySide;
