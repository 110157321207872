import React, {
    createContext,
    useState,
    PropsWithChildren,
    FC,
    useEffect,
    useContext,
} from "react";
import ReactGa from "react-ga4";
// @ts-ignore
import * as Klaro from "klaro/dist/klaro-no-css";
import klaroConfig from "./klaro-config";

const config = import.meta.env;

interface IProps {
    session_id: string;
}

Klaro.setup(klaroConfig);

const MEASUREMENT_ID = config.VITE_GA_MEASUREMENT_ID;

ReactGa.gtag("consent", "default", {
    ad_storage: "denied",
    ad_user_data: "denied",
    ad_personalization: "denied",
    analytics_storage: "denied",
});

const initializaGoogleAnalytics = (session_id: any) => {
    try {
        const config = import.meta.env;
        console.log("TEST ENV", config);
        ReactGa.initialize(MEASUREMENT_ID, {
            gaOptions: {
                userId: session_id,
            },
        });

        ReactGa.gtag("consent", "update", {
            analytics_storage: "granted",
        });
    } catch (error) {
        console.error(error);
    }
};

interface IContextProps {
    track : (data: any) =>void
}

const TrackingContext = createContext<IContextProps>({track: ()=> {}});

export const TrackingProvider: FC<PropsWithChildren<IProps>> = ({
    children,
    session_id,
}) => {
    const klaroConsentManager = Klaro.getManager(klaroConfig);

    klaroConsentManager.watch({
        update: (obj: any, name: any, data: any) => {
            // console.log("watcher", obj, name, data);
            const googleAnalyticsHasConsent = obj.consents?.googleAnalytics;

            if (googleAnalyticsHasConsent) {
                initializaGoogleAnalytics(session_id);
            } else {
            }
        },
    });

    useEffect(() => {
        const klaroConsentManager = Klaro.getManager(klaroConfig);
        const googleAnalyticsHasConsent =
            klaroConsentManager.consents?.googleAnalytics;
        if (googleAnalyticsHasConsent) {
            initializaGoogleAnalytics(session_id);
        } else {
        }

        return () => {};
    }, []);

    const track = (data: any) => {
        // tracker.track({
        //     event_key: "home.page.click.enroll",
        //     event_value: {
        //       user_id: 12345,
        //       course_id: course_id,
        //       time: 2016-02-23
        //     }
        //   })
        console.log("Track", data);
    };

    return (
        <TrackingContext.Provider value={{ track: track }}>
            {children}
        </TrackingContext.Provider>
    );
};

export const useTrackingContext = () => {
    const context = useContext(TrackingContext);

    if (!context) {
        throw new Error(
            "useTrackingContext must be used within a Tracking Provider"
        );
    }

    return context;
};
