import generateOptions from "@/components/contentful/common/richtext/richtext-options";
import {
    IAccordion
} from "@/lib/contentful/interfaces/components";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { usePage } from "@inertiajs/react";
import { Chip } from "@nextui-org/chip";
import { Accordion, AccordionItem } from "@nextui-org/react";
import { ScrollShadow } from "@nextui-org/scroll-shadow";
import { useIntersectionObserver } from "@uidotdev/usehooks";
import { cva, cx, VariantProps } from "class-variance-authority";
import { motion } from "framer-motion";
import React, { FC, HTMLAttributes, useEffect, useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { useTracking } from "react-tracking";
import ComponentResolver from "../../common/component-resolver";
import { useSection } from "../../lesson-page/section-context";

const componentContainer = cva("my-20 spacing-component-max-width", {
    variants: {
        backgroundColor: {
            Default: "",
            Primary: "bg-background-100",
            Secondary: "bg-secondary-900 text-secondary-foreground",
            None: "text-primary-foreground",
        },
    },
    defaultVariants: {
        backgroundColor: "Primary",
    },
});

const accordionBodyContainer = cva([""], {
    variants: {
        hasImage: {
            true: "lg:w-[50%] h-full",
            false: "w-full h-full",
        },
    },
    defaultVariants: {
        hasImage: false,
    },
});

interface AccordionWrapperProps
    extends HTMLAttributes<HTMLDivElement>,
        VariantProps<typeof componentContainer> {
    children: React.ReactNode;
    entry: IAccordion;
}

const AccordionComponent: FC<IAccordion> = (entry) => {
    const body = entry?.fields?.body || "";
    const title = entry?.fields?.title || "";
    const backgroundColor = entry?.fields?.backgroundColor || "Default";
  
    const content = entry?.fields?.content || [];

    return (
        <div>
            {/* <pre className="">{JSON.stringify(entry, null, 2)}</pre> */}
            <motion.div
                initial={{ opacity: 1, scale: 1 }}
                whileInView={{ opacity: 1 }}
                // viewport={{ root: scrollRef }}
            >
                <DivWrapper entry={entry} backgroundColor={backgroundColor}>
                    <div className={`flex flex-col  gap-8 w-full`}>
                        <h2 className="">{title}</h2>
                        <ScrollShadow
                            className={cx(
                                accordionBodyContainer({
                                    hasImage: false,
                                })
                            )}
                        >
                            {body
                                ? documentToReactComponents(
                                      body,
                                      generateOptions()
                                  )
                                : ""}
                        </ScrollShadow>

                        <div className="mb-4">
                            {Array.isArray(content) && (
                                <Accordion variant="shadow">
                                    {content?.map((cnt, ctx) => {
                                        return (
                                            <AccordionItem
                                                key={`key-${ctx}`}
                                                aria-label="Accordion 1"
                                                title={cnt?.fields?.title}
                                            >
                                                <ComponentResolver
                                                    field={cnt}
                                                />
                                            </AccordionItem>
                                        );
                                    })}
                                </Accordion>
                            )}
                        </div>

                 
                    </div>
                </DivWrapper>
            </motion.div>
        </div>
    );
};

const DivWrapper: FC<AccordionWrapperProps> = ({
    backgroundColor = "Primary",
    children,
    entry: { fields, sys },
}) => {
    const {
        auth,
        appName,
        lessonBackendData,
        courseId,
        lessonStatements,
    }: any = usePage().props;

    const { trackEvent, Track, getTrackingData }: any = useTracking({
        course_id: courseId,
        lesson_id: lessonBackendData?.contentful_id,
        section_id: sys?.id,
    });
    // const trackedData = getTrackingData();
    const { currentSectionId, setCurrentSectionId } = useSection();

    const [hasViewed, setHasViewed] = useState(false);
    const [hasStarted, setHasStarted] = useState(false);
    const [hasCompleted, setHasCompleted] = useState(false);
    const [hasSavedTracking, setHasSavedTracking] = useState(false);

    const handleLessonComplete = () => {
        if (!hasSavedTracking) {
            trackEvent({
                event: "completed",
                type: "lesson-section",
                object: {
                    lesson_id: lessonBackendData?.contentful_id,
                    section_id: sys?.id,
                    course_id: courseId,
                },
                props: { learnerId: 123 },
            });
            setHasSavedTracking(true);
        }
    };
    const [ref, entry] = useIntersectionObserver({
        threshold: 1,
        root: null,
        rootMargin: "0px",
    });

    useEffect(() => {
        const completedSectionStatement = lessonStatements.find(
            (stmt: any) =>
                stmt?.object?.lesson_id === lessonBackendData?.contentful_id &&
                stmt?.object?.section_id === sys?.id &&
                stmt?.verb === "completed"
        );
        if (completedSectionStatement) {
            if (!hasCompleted) {
                setHasCompleted(true);
            }
        }
    }, [lessonStatements, lessonBackendData?.contentful_id, sys?.id]);

    useEffect(() => {
        if (entry?.isIntersecting) {
            setCurrentSectionId(sys?.id);
            if (!hasStarted) {
                setHasStarted(true);
            }
        } else if (hasStarted && !hasCompleted) {
            setHasCompleted(true);
            handleLessonComplete();
        }
    }, [entry?.isIntersecting, hasStarted, hasCompleted, hasSavedTracking]);

    return (
        <div
            id={sys?.id}
            ref={ref}
            className={cx(componentContainer({ backgroundColor }))}
        >
            <motion.div
                initial={{ opacity: 0, y: 100 }}
                animate={{
                    opacity: hasStarted ? 1 : 0.7,
                    y: entry?.isIntersecting ? 0 : 5,
                    z: entry?.isIntersecting && !hasCompleted ? 1 : 10,
                }}
                transition={{ duration: 0.5 }}
                className="w-full"
            >
                {/* <pre className="">
                    {JSON.stringify(lessonStatements, null, 2)}
                </pre> */}
                <div className="py-2">{children}</div>
                {hasCompleted && (
                    <Chip
                        variant="faded"
                        startContent={<FaCheckCircle />}
                        color="success"
                    >
                        Completed
                    </Chip>
                )}
            </motion.div>
        </div>
    );
};

export default AccordionComponent;
