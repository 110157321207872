import {
    IInteractiveComponent
} from "@/lib/contentful/interfaces/components";
import { ISpot } from "@/lib/contentful/interfaces/topics";
import {
    createContext,
    ReactNode,
    useContext,
    useEffect,
    useState,
} from "react";
import { useTracking } from "react-tracking";

type InteractiveComponentContextType = {
    visitedHotspots: ISpot[];
    setVisitedHotspots: (spots: ISpot[]) => void;
    handleUpdateTracking: () => void;
};

const InteractiveComponentContext = createContext<
    InteractiveComponentContextType | undefined
>(undefined);

export const InteractiveComponentProvider = ({
    children,
    entry,
    lessonBackendData,
    lessonStatements,
}: {
    children: ReactNode;
    entry: IInteractiveComponent;
    lessonBackendData: any;
    lessonStatements: any;
}) => {
    const [visitedHotspots, setVisitedHotspots] = useState<ISpot[]>([]);
    const { trackEvent, Track, getTrackingData }: any = useTracking({
        lesson_id: lessonBackendData?.contentful_id,
        section_id: entry?.sys?.id,
    });
    const handleLessonComplete = () => {
        if (!false) {
            trackEvent({
                event: "completed",
                type: "lesson-section",
                object: {
                    lesson_id: lessonBackendData?.contentful_id,
                    section_id: entry?.sys?.id,
                },
                props: { learnerId: 123 },
            });
            // setHasSavedTracking(true);
        }
    };

    useEffect(() => {
        const statement = lessonStatements?.find(
            (e: any) => e?.object?.section_id === entry?.sys?.id && e?.result
        );
        if (statement) {
            if (statement?.result?.visitedHotspots) {
                setVisitedHotspots(statement?.result?.visitedHotspots);
            }
        }

        return () => {};
    }, [lessonStatements]);

    const handleUpdateTracking = () => {
        const statement = lessonStatements?.find(
            (e: any) => e?.object?.section_id === entry?.sys?.id && e?.result
        );
        if (statement) {
            if (statement?.result?.visitedHotspots) {
                if (
                    statement?.result?.visitedHotspots?.length <
                    entry?.fields?.component?.hotspots?.length
                ) {
                    trackEvent({
                        event: "completed",
                        type: "lesson-section",
                        object: {
                            lesson_id: lessonBackendData?.contentful_id,
                            section_id: entry?.sys?.id,
                        },
                        result: {
                            visitedHotspots,
                        },
                        props: { learnerId: 123 },
                    });
                }
            }
        }
        if (!statement) {
        }
    };
    useEffect(() => {
        return () => {};
    }, [visitedHotspots]);

    return (
        <InteractiveComponentContext.Provider
            value={{
                visitedHotspots,
                setVisitedHotspots,
                handleUpdateTracking,
            }}
        >
            {/* <pre className="">
                {JSON.stringify(
                    lessonStatements?.find(
                        (e : any) =>
                            e?.object?.section_id === entry?.sys?.id &&
                            e?.result
                    ),
                    null,
                    2
                )}
            </pre> */}

            {children}
        </InteractiveComponentContext.Provider>
    );
};

export const useInteractiveComponent = () => {
    const context = useContext(InteractiveComponentContext);
    if (!context) {
        throw new Error(
            "useInteractiveComponent must be used within a InteractiveComponentProvider"
        );
    }
    return context;
};
