import { BsDot } from "react-icons/bs";
import { FaCheckCircle } from "react-icons/fa";
import { ISpot } from "@/lib/contentful/interfaces/topics";
import { useInteractiveComponent } from "../interactive-component-context";

import {
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    useDisclosure,
} from "@nextui-org/react";

const Spot = ({
    top,
    left,
    spot,
}: {
    top: string;
    left: string;
    spot: ISpot;
}) => {
    const { isOpen, onOpen, onOpenChange } = useDisclosure();
    const { setVisitedHotspots, visitedHotspots } = useInteractiveComponent();

    const handleOnOpen = () => {
        onOpen();
        // @ts-ignore
        setVisitedHotspots((prev: ISpot[]) => {
         
            // Check if the spot already exists in the array
            if (prev.some((existingSpot) => existingSpot.id === spot.id)) {
                return prev; // Return previous state if spot already exists
            }

            // Add the new spot to the array if it doesn't exist
            return [...prev, spot];
        });
    };



    const hasVisited = visitedHotspots?.find((e) => e?.id === spot?.id);
    return (
        <div style={{ top, left }} className="absolute ">
            {/* <pre className="">{JSON.stringify(visitedHotspots, null, 2)}</pre> */}
            {hasVisited ? (
                <FaCheckCircle
                    onClick={handleOnOpen}
                    className="text-success cursor-pointer"
                    size={20}
                />
            ) : (
                <span
                    onClick={handleOnOpen}
                    className="absolute spot bg-warning shadow-lg flex items-center justify-items-center"
                >
                    <BsDot />
                </span>
            )}

            <Modal size="2xl" isOpen={isOpen} onOpenChange={onOpenChange}>
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">
                                {spot?.title}
                            </ModalHeader>
                            <ModalBody>
                                <p className="">{spot?.description}</p>
                            </ModalBody>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </div>
    );
};

export default Spot;
