import {
    IInteractiveComponent
} from "@/lib/contentful/interfaces/components";
import { usePage } from "@inertiajs/react";
import { FC } from "react";
import InteractiveComponent from "./interactive-component";
import {
    InteractiveComponentProvider
} from "./interactive-component-context";

const InteractiveComponentWrapper: FC<IInteractiveComponent> = (entry) => {
    const {
        auth,
        appName,
        lessonBackendData,
        courseId,
        lessonStatements,
    }: any = usePage().props;
    return (
        <InteractiveComponentProvider entry={entry} lessonStatements={lessonStatements} lessonBackendData={lessonBackendData}>
            <InteractiveComponent {...entry} />
        </InteractiveComponentProvider>
    );
};

export default InteractiveComponentWrapper;
