import { extractYouTubeVideoID } from "@/lib/contentful/helpers/common";
import { IVideoWrapper } from "@/lib/contentful/interfaces/topics";
import { Chip } from "@nextui-org/chip";
import WistiaPlayer from "@/components/contentful/common/wistia-player";
import CommonVideoPlayer from "@/components/contentful/common/common-video-player";

import YouTubeVideo from "@/components/ui/youtube-video/youtube-video";

const VideoMediaWrapper = (entry: IVideoWrapper) => {
    const contentfulVideoUrl: any =
        entry?.fields?.contentfulVideo?.fields?.file?.url || "";

    const videoSource = entry?.fields?.videoSource || "Youtube";

    const videoUrl = entry?.fields?.url || "";
    const videoId = entry?.fields?.videoId || "";

    const videoTitle = entry?.fields?.title || "";

    // if (!img?.alt) {
    //   return <>...</>;
    // }

    const onVideoProgress = (playedPercentage: any) => {
        if (playedPercentage > 80) {
            //  track progress
        }
    };
    if (videoSource === "Youtube") {
        return (
            <div className="w-full">
                <YouTubeVideo videoId={extractYouTubeVideoID(videoUrl) || ""} />
            </div>
        );
    }

    if (videoSource === "Contentful" && contentfulVideoUrl) {
        return (
            <div>
                <video className="w-full" controls>
                    <source src={contentfulVideoUrl || ""} />
                    Your browser does not support the video tag.
                </video>
            </div>
        );
    }

    if (videoSource === "Wistia" && videoId) {
        return (
            <div className="spacing-component-max-width spacing-component-padding">
                <div className=" w-full mb-2 min-h-[400px]">
                    <CommonVideoPlayer
                        onProgress={onVideoProgress}
                        url={`https://home.wistia.com/medias/${videoId}`}
                    />
                </div>
            </div>
        );
    }

    return (
        <div className="flex items-center justify-center justify-items-center w-full p-20">
            <Chip color="warning">Not implemented </Chip>
        </div>
    );
};

export default VideoMediaWrapper;
