import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { IInteractiveComponent } from "@/lib/contentful/interfaces/components";
import { CheckboxGroup } from "@nextui-org/react";
import { Checkbox, Link, User, Chip, cn, Button } from "@nextui-org/react";




const SimpleQuiz: React.FC<IInteractiveComponent> = (entry) => {
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
    const [score, setScore] = useState<number>(0);
    const [showScore, setShowScore] = useState<boolean>(false);
    const [showSuccess, setShowSuccess] = useState<boolean>(false);
    const [showFail, setShowFail] = useState<boolean>(false);
    const [hasResponded, setHasResponded] = useState<boolean>(false);
    const [selected, setSelected] = useState([]);


    
 
    
    const handleCheckQuizAnswer = () => {
        setHasResponded(true)
        setShowFail(false)
        setShowSuccess(false)
        // Extract relevant options
        const relevantOptions =
            entry?.fields?.component?.content?.quiz?.options?.filter(
                (e: any) => e?.code === "relevant"
            );
        // Extract answers from relevant options
        const relevantAnswers =
            relevantOptions?.map((option: any) => option.answer) || [];
        // Convert relevantAnswers and selected to sets
        const relevantAnswersSet = new Set(relevantAnswers);
        const selectedSet = new Set(selected);
        // Check if the sets are equal
        const isMatch =
            relevantAnswersSet.size === selectedSet.size &&
            [...relevantAnswersSet].every((answer: any) =>
                // @ts-ignore
                selectedSet.has(answer)
            );
        if (isMatch) {
            // yay!
            setShowSuccess(true)
        } else {
            // oh no
            setShowFail(true)
        }
    };

    return (
        <div className="flexx justify-center items-center w-full ">
            {/* <pre className="">{JSON.stringify(entry)}</pre> */}
            <div className="w-full p-8  rounded-lg shadow-md">
                <AnimatePresence>
                    {showSuccess ? (
                        <motion.div
                            key="score"
                            initial={{ opacity: 0, y: 50 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -50 }}
                            className="text-center"
                        >
                            <div className="text-2xl font-bold border-success bg-success-50 rounded-md p-10">
                               Yay! that was Correct!
                            </div>
                        </motion.div>
                    ) : (
                        <motion.div
                            key={currentQuestionIndex}
                            initial={{ opacity: 0, y: 50 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -50 }}
                            className="text-centerx"
                        >
                            <div className="text-xl font-semibold mb-4 text-inherit">
                                {
                                    entry?.fields?.component?.content?.quiz
                                        ?.question
                                }
                            </div>
                            <div className="w-full">
                                <CheckboxGroup
                                    // label="Select cities"
                                    defaultValue={[]}
                                    value={selected}
                                    // @ts-ignore
                                    onValueChange={setSelected}
                                    className="w-full"
                                >
                                    {entry?.fields?.component?.content?.quiz?.options?.map(
                                        (option: any, optx: number) => {
                                            return (
                                                <Checkbox
                                                    key={`key-${optx}`}
                                                    value={option?.answer}
                                                    classNames={{
                                                        base: cn(
                                                            "inline-flex max-w-xlx min-w-full w-full bg-content1 m-0",
                                                            "hover:bg-content2 items-center justify-start",
                                                            "cursor-pointer rounded-lg gap-2 p-4 border-2 border-transparent",
                                                            `${hasResponded && showFail ? "data-[selected=true]:border-danger animate-pulse" : "data-[selected=true]:border-primary-100"} `
                                                        ),
                                                        label: "w-full",
                                                    }}
                                                >
                                                    {option?.answer}
                                                </Checkbox>
                                            );
                                        }
                                    )}
                                </CheckboxGroup>
                                {/* <p className="text-default-500 text-small">
                                    Selected: {selected.join(", ")}
                                </p> */}

                                <div className="w-full my-4">
                                    {hasResponded && showFail ?  <Button
                                        onClick={()=>{
                                            setHasResponded(false)
                                            setShowFail(false)
                                            setSelected([])
                                        }}
                                        size="lg"
                                        color="danger"
                                          className="w-full"
                                    >
                                        Try again
                                    </Button> :  <Button
                                        onClick={handleCheckQuizAnswer}
                                        size="lg"
                                        color="primary"
                                        className="w-full"
                                    >
                                        Submit
                                    </Button>}
                                   
                                </div>
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        </div>
    );
};

export default SimpleQuiz;
