import generateOptions from "@/components/contentful/common/richtext/richtext-options";
import { IInteractiveComponent } from "@/lib/contentful/interfaces/components";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { usePage } from "@inertiajs/react";
import { Chip } from "@nextui-org/chip";
import { ScrollShadow } from "@nextui-org/scroll-shadow";
import { useIntersectionObserver } from "@uidotdev/usehooks";
import { cva, cx, VariantProps } from "class-variance-authority";
import { motion } from "framer-motion";
import React, { FC, HTMLAttributes, useEffect, useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { useTracking } from "react-tracking";
import { useSection } from "../../lesson-page/section-context";
import HotspotViewer from "./hotspot-viewer/hotspot-viewer";
import { useInteractiveComponent } from "./interactive-component-context";
import SimpleQuiz from "./simple-quiz/simple-quiz";

const componentContainer = cva("my-20 mb-4x", {
    variants: {
        backgroundColor: {
            Default: " bg-content1",
            Primary: " bg-content3",
            Secondary: "text-secondary-foregroundx",
            None: "",
        },
    },
    defaultVariants: {
        backgroundColor: "Primary",
    },
});

const interactiveBodyContainer = cva([""], {
    variants: {
        hasImage: {
            true: "lg:w-[50%] h-full",
            false: "w-full h-full",
        },
    },
    defaultVariants: {
        hasImage: false,
    },
});

interface InteractiveWrapperProps
    extends HTMLAttributes<HTMLDivElement>,
        VariantProps<typeof componentContainer> {
    children: React.ReactNode;
    entry: IInteractiveComponent;
}

const InteractiveComponent: FC<IInteractiveComponent> = (entry) => {
    const body = entry?.fields?.body || "";
    const title = entry?.fields?.title || "";
    const backgroundColor = entry?.fields?.backgroundColor || "Default";

    const componentContent = entry?.fields?.component || {};
    const content = entry?.fields?.body || {};
    const componentType = componentContent?.type || {};

    const {
        auth,
        appName,
        lessonBackendData,
        courseId,
        lessonStatements,
    }: any = usePage().props;

    return (
        <div>
            {/* <pre className="">{JSON.stringify(visitedHotspots, null, 2)}</pre> */}
            <motion.div
                initial={{ opacity: 1, scale: 1 }}
                whileInView={{ opacity: 1 }}
                // viewport={{ root: scrollRef }}
            >
                <DivWrapper entry={entry} backgroundColor={backgroundColor}>
                    <div className={`flex flex-col lg:flex-rowx gap-8 w-full`}>
                        {/* <h2 className="">{title}</h2> */}
                        <ScrollShadow
                            className={cx(
                                interactiveBodyContainer({
                                    hasImage: false,
                                })
                            )}
                        >
                            {" "}
                            <div className="flex flex-col gap-8 w-[50%]x">
                                <h2 className="">{title}</h2>
                                {body
                                    ? documentToReactComponents(
                                          body,
                                          generateOptions()
                                      )
                                    : ""}
                            </div>
                        </ScrollShadow>
                        <div className="w-[50%]x">
                            {entry?.fields?.component?.type === "hotspots" && (
                                <HotspotViewer {...entry} />
                            )}

                            {entry?.fields?.component?.type ===
                                "simple-quiz" && <SimpleQuiz {...entry} />}

                            {/* <SimpleQuiz /> */}
                        </div>
                    </div>
                </DivWrapper>
            </motion.div>
        </div>
    );
};

const DivWrapper: FC<InteractiveWrapperProps> = ({
    backgroundColor = "Primary",
    children,
    entry: { fields, sys },
}) => {
    const {
        auth,
        appName,
        lessonBackendData,
        courseId,
        lessonStatements,
    }: any = usePage().props;

    const { trackEvent, Track, getTrackingData }: any = useTracking({
        course_id: courseId,
        lesson_id: lessonBackendData?.contentful_id,
        section_id: sys?.id,
    });
    // const trackedData = getTrackingData();
    const { currentSectionId, setCurrentSectionId } = useSection();

    const [hasViewed, setHasViewed] = useState(false);
    const [hasStarted, setHasStarted] = useState(false);
    const [hasCompleted, setHasCompleted] = useState(false);
    const [hasSavedTracking, setHasSavedTracking] = useState(false);
    const { setVisitedHotspots, visitedHotspots, handleUpdateTracking } =
        useInteractiveComponent();

    // const { setVisitedHotspots, visitedHotspots } = useInteractiveComponent();

    const handleLessonComplete = () => {
        if (!hasSavedTracking) {
            trackEvent({
                event: "completed",
                type: "lesson-section",
                object: {
                    lesson_id: lessonBackendData?.contentful_id,
                    section_id: sys?.id,
                    course_id: courseId,
                },
                props: { learnerId: 123 },
            });
            setHasSavedTracking(true);
        }
    };
    const [ref, entry] = useIntersectionObserver({
        threshold: 1,
        root: null,
        rootMargin: "0px",
    });

    useEffect(() => {
        const completedSectionStatement = lessonStatements.find(
            (stmt: any) =>
                stmt?.object?.lesson_id === lessonBackendData?.contentful_id &&
                stmt?.object?.section_id === sys?.id &&
                stmt?.verb === "completed"
        );
        if (completedSectionStatement) {
            if (!hasCompleted) {
                setHasCompleted(true);
            }
        }
    }, [lessonStatements, lessonBackendData?.contentful_id, sys?.id]);

    useEffect(() => {
        if (entry?.isIntersecting) {
            setCurrentSectionId(sys?.id);
            if (!hasStarted) {
                setHasStarted(true);
            } else handleUpdateTracking();
        } else if (hasStarted && !hasCompleted) {
            setHasCompleted(true);
            handleLessonComplete();
        }
    }, [entry?.isIntersecting, hasStarted, hasCompleted, hasSavedTracking]);

    return (
        <div
            id={sys?.id}
            ref={ref}
            className={cx(componentContainer({ backgroundColor }))}
        >
            <motion.div
                initial={{ opacity: 0, y: 100 }}
                animate={{
                    opacity: hasStarted ? 1 : 0.7,
                    y: entry?.isIntersecting ? 0 : 5,
                    z: entry?.isIntersecting && !hasCompleted ? 1 : 10,
                }}
                transition={{ duration: 0.5 }}
                className="w-full spacing-component-max-width "
            >
                {/* <pre className="">
                    {JSON.stringify(lessonStatements, null, 2)}
                </pre> */}
                <div className="py-2">{children}</div>
                {hasCompleted && (
                    <Chip
                        variant="faded"
                        startContent={<FaCheckCircle />}
                        color="success"
                    >
                        Completed
                    </Chip>
                )}
            </motion.div>
        </div>
    );
};

export default InteractiveComponent;
