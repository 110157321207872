import { componentMap } from "@/components/contentful/common/mapping";
import React from "react";
import ErrorBoundary from "@/components/error-boundary";
import { Chip } from "@nextui-org/react";

// eslint-disable-next-line react/display-name
const ComponentResolver = React.forwardRef((props: any, ref) => {
    const { inline = false, field } = props;
    const entryId = field?.sys?.id;
    const contentType = field?.sys?.contentType?.sys?.id;

    const Component = componentMap[contentType];

    if (!Component) {
        return <>!{contentType}</>;
    }

    return (
        <ErrorBoundary
            fallback={
                <div className="w-full shadow-lg flex items-center max-w-xlx spacing-component-max-width spacing-component-padding bg-danger-100 p rounded-md">
                    <Chip color="warning">Something went wrong</Chip>
                </div>
            }
        >
            <div className="">
                <Component {...field} {...props} className={props.className} />
            </div>
        </ErrorBoundary>
    );
});

export default ComponentResolver;
