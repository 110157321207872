import SampleComponent from "@/components/contentful/sample-component/sample-component";
import HeroBannerWrapper from "@/components/contentful/hero-banner-wrapper/hero-banner-wrapper";
import CtaWrapper from "@/components/contentful/cta-wrapper/cta-wrapper";
import RichContentBlockWrapper from "@/components/contentful/rich-content-block-wrapper/rich-content-block-wrapper";
import PexelsMediaWrapper from "@/components/contentful/pexels-image-wrapper/pexels-image-wrapper";
import MediaWrapper from "@/components/contentful/media-wrapper/media-wrapper";
import VideoMediaWrapper from "@/components/contentful/video-wrapper/video-wrapper";
import SideBySide from "@/components/contentful/e-learning/side-by-side-wrapper/side-by-side-wrapper";
import AccordionComponent from "../e-learning/accordion/accordion";
import InteractiveComponent from "../e-learning/interactive-component/interactive-component";
import TopicDefinitionWrapper from "@/components/contentful/topic-definition/topic-definition-wrapper";
import CodeSnippetWrapper from "../code-snippet-wrapper/code-snippet-wrapper";

import InteractiveWrapper from "@/components/contentful/e-learning/interactive-component/interactive-component-wrapper";
import InlinePersonWrapper from "@/components/contentful/person/inline-person-wrapper";

export const componentMap: any = {
    heroBanner: HeroBannerWrapper,
    videoWrapper: VideoMediaWrapper,
    imageWrapper: MediaWrapper,
    person: InlinePersonWrapper,
    topicDefinition: TopicDefinitionWrapper,
    sideBySide: SideBySide,
    accordion: AccordionComponent,
    richContentBlock: RichContentBlockWrapper,
    cta: CtaWrapper,
    interactiveComponent: InteractiveWrapper,
    pexelsImageWrapper: PexelsMediaWrapper,
    codeSnippet: CodeSnippetWrapper,
};
