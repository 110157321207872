import { createContext, useContext, useState, ReactNode } from 'react';

type SectionContextType = {
    currentSectionId: string | null;
    setCurrentSectionId: (id: string) => void;
};

const SectionContext = createContext<SectionContextType | undefined>(undefined);

export const SectionProvider = ({ children, defaultSectionId }: { children: ReactNode, defaultSectionId: string; }) => {
    const [currentSectionId, setCurrentSectionId] = useState<string | null>(defaultSectionId);

    return (
        <SectionContext.Provider value={{ currentSectionId, setCurrentSectionId }}>
            {children}
        </SectionContext.Provider>
    );
};

export const useSection = () => {
    const context = useContext(SectionContext);
    if (!context) {
        throw new Error('useSection must be used within a SectionProvider');
    }
    return context;
};
