import { ISpot } from "@/lib/contentful/interfaces/topics";
import { motion } from "framer-motion";
import { FC } from "react";
import Spot from "./spot";

interface IImage {
    spotsMap: ISpot[];
    src: string;

    // handleEditSpotDetails: (spot: ISpot) => void;
}

const ImageMap: FC<IImage> = ({ spotsMap, src }) => {
    return (
        <motion.div
            initial={{ y: 90 }}
            whileInView={{ y: 0 }}
            className="relative  max-w-[700px]"
        >
            <div className="image-map ">
                <img src={src} className="" />
             
                {spotsMap.map((spot, i) => (
                    <Spot spot={spot} key={i} top={spot.y} left={spot.x} />
                ))}
            </div>
        </motion.div>
    );
};

export default ImageMap;
