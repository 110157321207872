import React, {
  useRef,
  useEffect,
  useState,
  useContext,
  useCallback,
  useMemo,
  forwardRef,
} from "react";
import ReactPlayer, { ReactPlayerProps } from "react-player";
// import { secondsToMinutes } from "@/helpers/time-helpers";
import { secondsToMinutes } from "date-fns/fp";

interface Props {
  url: string;
  onProgress: (played: number) => void;
}

const CommonVideoPlayer = ({ url, onProgress }: Props) => {
  const reactPlayerRef = useRef<ReactPlayer | null>(null);

  const [playing, setPlaying] = useState(false);
  const [played, setPlayed] = useState(0);
  const [duration, setDuration] = useState(0);
  const [seeking, setSeeking] = useState(false);

  const onVideoPlaybackProgress = (e: any) => {
    const videoDuration = reactPlayerRef?.current?.getDuration() || 0;
    const playedPercentage = ((played * videoDuration) / videoDuration) * 100;
    console.log("onVideoPlaybackProgress playing", playedPercentage);
    setPlayed(e?.played);
    onProgress(playedPercentage);
    if (playedPercentage > 80) {
      //   props?.trackAndUpdateUserActivity(entryId, "played", "true");
      //   props?.trackAndUpdateUserActivity(entryId, "completed", "true");
    }
  };

  const handleVideoPlay = () => {
    setPlaying(true);
  };
  const handleVideoPause = () => {
    setPlaying(false);
  };

  const handleSeekMouseUp = (e: any) => {
    setSeeking(false);

    try {
      reactPlayerRef.current?.seekTo(parseFloat(e.target.value));
    } catch (error) {}
  };

  const handleSeekMouseDown = (e: any) => {
    setSeeking(true);
  };
  const handleSeekChange = (e: any) => {
    setPlayed(parseFloat(e?.target?.value));
  };

  const handleDuration = (e: any) => {
    console.log("onDuration", e);
    setDuration(e);
  };

  return (
    <div className="h-full w-full flex flex-col bg-primary">
      <div className="h-full w-full relative">
        <ReactPlayer
        
          key={url}
          config={{
            wistia: {
              options: {
                controlsVisibleOnLoad: true,
                fitStrategy: "contain",
                keyMoments: true,
                resumable: true,
                seo: true,
                videoFoam: true,
                plugin: {
                  "captions-v1": {
                    onByDefault: false,
                    // language: "en",
                  },
                },
              },
            },
          }}
          width="100%"
          height="100%"
          controls={true}
          ref={reactPlayerRef}
          playing={playing}
          onSeek={(e) => console.log("onSeek", e)}
          onPlay={handleVideoPlay}
          onPause={handleVideoPause}
          onProgress={onVideoPlaybackProgress}
          onDuration={handleDuration}
          url={url}
        />
      </div>
      <div className="flex space-x-2 justify-between w-full hidden">
        <div className="">play/pause</div>
        <div className="">{secondsToMinutes(duration * played)} </div>
        <div className="w-fullx">
          <input
            type="range"
            className="w-full"
            min={0}
            max={0.999999}
            step="any"
            value={played}
            onMouseDown={handleSeekMouseDown}
            onChange={handleSeekChange}
            onMouseUp={handleSeekMouseUp}
          />
        </div>
        <div className="">CC</div>
        <div className="">volume</div>
        <div className="">settings</div>
        <div className="">fullscreen</div>
      </div>
    </div>
  );
};

export default CommonVideoPlayer;
