import {
    IInteractiveComponent
} from "@/lib/contentful/interfaces/components";
import { ISpot } from "@/lib/contentful/interfaces/topics";
import { FC } from "react";
import ImageMap from "./image-map";

interface IAppFields {
    hotspots: ISpot[];
    image: any; // assuming it's a URL to the image
    title: string;
}

export interface IHotspotEntry {
    fields: {
        app: IAppFields;
        title: string;
    };
}

const HotspotViewer: FC<IInteractiveComponent> = (entry) => {
    const image: any = entry?.fields?.component?.image;

    return (
        <div className="relative w-full h-full   ">
            <ImageMap
                src={image.fields?.file?.["en-US"]?.url || ""}
                spotsMap={entry?.fields?.component?.hotspots}
            />
        </div>
    );
};

export default HotspotViewer;
